@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Playfair-Display';
  src: url('/assets/fonts/PlayfairDisplay-Bold.ttf');
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

/* Common */
.container {
  display: grid;
  grid-gap: 40px;
}

.content__dp {
  border-radius: 100%;
  width: 11rem;
  height: auto;
  padding: 10px;
  justify-self: center;
}

/* Home */
.showcase {
  position: relative;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(
      134deg,
      rgba(1, 1, 1, 0.02) 0%,
      rgba(1, 1, 1, 0.02) 16%,
      transparent 16%,
      transparent 76%,
      rgba(58, 58, 58, 0.02) 76%,
      rgba(58, 58, 58, 0.02) 100%
    ),
    linear-gradient(
      215deg,
      rgba(166, 166, 166, 0.02) 0%,
      rgba(166, 166, 166, 0.02) 33%,
      transparent 33%,
      transparent 79%,
      rgba(111, 111, 111, 0.02) 79%,
      rgba(111, 111, 111, 0.02) 100%
    ),
    linear-gradient(
      303deg,
      rgba(215, 215, 215, 0.02) 0%,
      rgba(215, 215, 215, 0.02) 7%,
      transparent 7%,
      transparent 90%,
      rgba(192, 192, 192, 0.02) 90%,
      rgba(192, 192, 192, 0.02) 100%
    ),
    linear-gradient(
      302deg,
      rgba(113, 113, 113, 0.02) 0%,
      rgba(113, 113, 113, 0.02) 34%,
      transparent 34%,
      transparent 73%,
      rgba(65, 65, 65, 0.02) 73%,
      rgba(65, 65, 65, 0.02) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
}

.content {
  z-index: 1;
  display: grid;
  margin: 0 20px;
}

.bio {
  margin: 10px;
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 300;
  color: #444;
}

.eth {
  font-weight: 300;
  font-size: 1.5rem;
}

.bio-links {
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(3, 80px);
  grid-gap: 30px;
  place-content: center center;
}

.bio-link {
  padding: 10px;
  border: 1.25px solid #000428;
  font-size: 1rem;
  font-weight: 400;
  color: #000428;
  text-decoration: none;
}

.bio-link:hover {
  background: #000428;
  color: #fff;
  /* transform: skew(10deg); */
  transition: 0.2s ease-in-out;
}

.work-link {
  /* transform: skew(10deg); */
}

.work-link:hover {
  /* transform: skew(0deg); */
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
}

.name {
  font-family: 'Playfair-Display', serif !important;
  font-size: 6rem;
  margin: 0;
  background: linear-gradient(to right, #004e92 10%, #000428 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social {
  grid-area: social;
  display: grid;
  justify-content: end;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 35px);
}

.links {
  text-decoration: none;
  color: #004e92;
  /* border-bottom: 1px solid #004e92; */
}

/* Work */
.divider {
  display: grid;
  background: linear-gradient(to right, #004e92 0%, #000428 100%);
  justify-content: center;
}

.featured-box {
  display: grid;
  gap: 20px;
}

.section-header {
  align-self: center;
  color: #fff;
  font-size: 1.5em;
  padding: 10px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px #000000;
}

.quote-box {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 50px) auto;
}

.quote-icon {
  color: #bbb;
}

.quotes {
  font-size: 1.25rem;
  font-weight: 100;
}

.reverse-quote {
  transform: rotate(180deg);
}

.material-icons {
  font-size: 3em;
}

.project-area {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-gap: 40px;
}

.project-card {
  background: #fff;
  display: grid;
  grid-template-rows: 220px auto;
  font-weight: 300;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.project-img {
  height: 100%;
  width: 100%;
  filter: grayscale(0%);
}

.project-card-body {
  display: grid;
  gap: 20px;
  text-align: left;
  align-items: center;
  padding: 15px;
}

.about-me-body {
  align-items: start;
  display: block;
  line-height: 1.5em;
}

.line-height {
  line-height: 1.5em;
}

.project-heading {
  align-self: center;
  font-weight: 700;
}

.button-container {
  display: grid;
  font-size: 0.9em;
  grid-template-columns: 55px 45px auto;
}

.project-timeline {
  justify-self: end;
  color: #aaa;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.buttons {
  font-size: 0.9em;
  border-bottom: none;
  color: #004e92;
  font-weight: 700;
  text-transform: uppercase;
}

.school-area {
  gap: 20px 40px;
  grid-template-columns: repeat(2, 450px);
}

.align-center {
  text-align: center;
}

.about-card {
  box-shadow: none;
}
.about-card:hover {
  box-shadow: none;
}

.about-card-head {
  display: grid;
  place-content: center center;
  border-bottom: 1px solid #ccc;
}

.about-card-body {
  padding: 20px;
  grid-template-columns: none;
}

.about-card-title {
  text-align: center;
}

.about-heading {
  text-transform: capitalize;
}

.about-header {
  background-image: url('/assets/bg/midtown.jpg');
  background-attachment: fixed;
  background-position: 50% 70%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.divider-heading {
  font-size: 2.25em;
}

.contest-box {
  display: grid;
  gap: 20px;
}

.contest {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
}

.contest-img {
  width: 150px;
  height: auto;
  filter: grayscale(0%);
}

.img-link {
  text-decoration: none;
  border-bottom: none;
}

/* About */
.about-me {
  background-image: linear-gradient(
      330deg,
      rgba(225, 225, 225, 0.05) 0%,
      rgba(225, 225, 225, 0.05) 33.333%,
      rgba(114, 114, 114, 0.05) 33.333%,
      rgba(114, 114, 114, 0.05) 66.666%,
      rgba(52, 52, 52, 0.05) 66.666%,
      rgba(52, 52, 52, 0.05) 99.999%
    ),
    linear-gradient(
      66deg,
      rgba(181, 181, 181, 0.05) 0%,
      rgba(181, 181, 181, 0.05) 33.333%,
      rgba(27, 27, 27, 0.05) 33.333%,
      rgba(27, 27, 27, 0.05) 66.666%,
      rgba(251, 251, 251, 0.05) 66.666%,
      rgba(251, 251, 251, 0.05) 99.999%
    ),
    linear-gradient(
      225deg,
      rgba(98, 98, 98, 0.05) 0%,
      rgba(98, 98, 98, 0.05) 33.333%,
      rgba(222, 222, 222, 0.05) 33.333%,
      rgba(222, 222, 222, 0.05) 66.666%,
      rgba(228, 228, 228, 0.05) 66.666%,
      rgba(228, 228, 228, 0.05) 99.999%
    ),
    linear-gradient(90deg, rgb(28, 20, 63), rgb(40, 160, 253));
}

.about-card__heading {
  text-align: center;
  padding: 20px 20px 0 20px;
}

.school {
  background-image: url('/assets/bg/cornell-bg.jpg');
  background-attachment: fixed;
  background-position: 50% 0%;
}

.award {
  background-image: url('/assets/bg/isaa.jpg');
  background-attachment: fixed;
  background-position: 50% 0%;
}

.award-img {
  height: 130px;
  width: 130px;
  filter: grayscale(0%);
}

.cert-img {
  width: 170px;
  height: auto;
  filter: grayscale(0%);
}

/* Layout */
.active {
  color: #111111 !important;
}

header {
  background: #fff;
  margin: 0 auto;
  padding: 20px 40px;
  display: grid;
  justify-items: end;
  text-align: center;
  align-items: center;
  grid-template-columns: auto repeat(3, 70px);
  grid-gap: 10px;
  font-size: 1.25em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.logo {
  text-decoration: none;
  justify-self: start;
  font-family: 'Playfair-Display', serif !important;
  font-size: 1.25em;
  background: linear-gradient(to right, #004e92 0%, #000428 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo:hover {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.link:hover {
  transition: 0.2s ease-in-out;
}

.link {
  text-decoration: none;
  color: #bbb;
  font-weight: 700;
}

footer {
  margin-top: 20px;
  background: #25282a;
  color: #fff;
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.footer-title {
  font-size: 1.5em;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}

.footer-link {
  display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-gap: 20px;
  justify-content: end;
  text-align: center;
  font-size: 1.1em;
}

.copyright-box {
  display: grid;
  font-size: 0.8em;
}

.rights {
  color: #bbb;
}

.social-icons {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 35px);
  font-weight: 300;
  justify-content: end;
  align-items: end;
}

.social-icon {
  width: 30px;
  height: 30px;
  filter: grayscale(100%);
  transition: 0.2s ease-out;
}

.social-icon:hover {
  transition: 0.2s ease-out;
  filter: grayscale(0%);
}

a {
  cursor: pointer;
}

.home-link {
  justify-self: end;
  align-self: center;
}

/* Breakpoints */
@media only screen and (max-width: 485px) {
  .project-area {
    grid-template-columns: auto;
  }

  .showcase {
    height: 85vh;
    background-image: none;
  }

  .name {
    font-size: 2.75rem;
  }

  .bio {
    font-size: 1rem;
    font-weight: 500;
  }

  .bio-link {
    padding: 5px 7px;
    font-size: 0.9rem;
  }

  .contest {
    grid-template-columns: auto;
  }

  .school,
  .award,
  .about-header {
    display: grid;
    background: linear-gradient(to right, #004e92 0%, #000428 100%);
    justify-content: center;
    height: auto;
  }

  .divider-heading {
    font-size: 1em;
  }

  .social {
    justify-content: center;
    margin-top: 20px;
  }

  header {
    padding: 20px;
    font-size: 1em;
  }
  footer {
    padding: 10px;
  }
  .logo span,
  .footer-title span {
    display: none;
  }

  .logo:after,
  .footer-title:after {
    content: 'RS';
  }
}

@media only screen and (min-width: 486px) and (max-width: 767px) {
  .project-area {
    grid-template-columns: repeat(2, 1fr);
  }

  .contest {
    grid-template-columns: repeat(4, 1fr);
  }

  .contest-img {
    width: 110px;
    height: auto;
    filter: grayscale(0%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1090px) {
  .project-area {
    grid-template-columns: repeat(3, 1fr);
  }

  .contest {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Badge */
.badge1 {
  position: relative;
}
.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -9px;
  right: -9px;
  font-size: 1rem;
  background: #fa3e3e;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}

.cert-head {
  border-bottom: none;
}
